import Image from "next/image"
import { ReactNode } from "react"

const IdeaElement: React.FC<{
  src: string
  alt: string
  icon: ReactNode
  heading: string
  text: string
  reverse?: boolean
}> = ({ src, alt, icon, heading, text, reverse = false }) => {
  const textBox = (
    <div>
      <h2 className='flex items-center gap-5 text-5xl font-semibold tracking-[1px] text-white16 mb-5'>
        {icon}
        {heading}
      </h2>
      <p className='text-3xl font-medium text-white48 leading-relaxed'>
        {text}
      </p>
    </div>
  )

  const imgBox = (
    <div className=' rounded-[20px] my-0 mx-auto overflow-hidden scale-90'>
      <Image
        className='w-full scale-125'
        src={src}
        alt={alt}
        width={500}
        height={500}
      />
    </div>
  )

  return reverse ? (
    <>
      {imgBox}
      {textBox}
    </>
  ) : (
    <>
      {textBox}
      {imgBox}
    </>
  )
}
export default IdeaElement
