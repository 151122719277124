"use client"
import Footer from "@/components/UI/footer/footer"
import Topbar from "@/components/UI/navbar/topbar"
import {
  AcademicCapIcon,
  BoltIcon,
  BookOpenIcon,
  HandRaisedIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline"
import { CalendarIcon } from "@heroicons/react/24/outline"
import { StarIcon } from "@heroicons/react/24/outline"
import { useState } from "react"

import faqData from "@/content/faq.json" assert { type: "json" }
import { CalculatorIcon } from "@heroicons/react/24/outline"
import ContentElement from "@/components/UI/ContentElement/ContentElement"
import ToolElement from "@/components/home/tools/tool"
import IdeaElement from "@/components/home/ideals/idea"
import HeroSection from "@/components/hero"
import QuestionElement from "@/components/home/faq/question"

const Home: React.FC = () => {
  const [visibleAnswers, setVisibleAnswers] = useState(-1)

  const handleVisible = (number: number) => {
    setVisibleAnswers(number)
  }

  return (
    <>
      <Topbar />
      <main>
        <HeroSection
          src='/home/baner.webp'
          heading='SzkolnyPrzewodnik,'
          other={{
            italic: "Edukacyjna przyszłość zaczyna się tutaj.",
            text: "Szkolny Przewodnik to platforma edukacyjna stworzona z myślą o uczniach szkół podstawowych i ich rodzicach.",
            href: "#functions",
          }}
        />

        {/* <section id='partners'></section> */}

        <ContentElement
          className='p-20 m-0 text-center mb-40'
          heading={"Skorzystaj z naszych narzędzi w życiu codziennym"}
          subheading={"Oferowane funkcje"}
          childrenClassName='grid grid-cols-3 justify-stretch text-left mx-0 my-auto gap-40'
          id='functions'>
          <ToolElement
            href='/app/doradztwo'
            src='/functions/school-img.jpg'
            alt='School Icon'
            icon={<AcademicCapIcon className='size-8' />}
            heading='Doradztwo'
            text='Wybierz za pomocą naszego narzędzia szkołę która do ciebie pasuje.'
          />
          <ToolElement
            href='/app'
            src='/functions/notebook-img.jpg'
            alt='Notebook Icon'
            icon={<PencilSquareIcon className='size-8' />}
            heading='Notatki'
            text='Ucz się przy pomocy notatek przygotowanych przez nasz zespół.'
          />
          <ToolElement
            href='/app/kalendarz'
            src='/functions/calendar-img.jpg'
            alt='Calendar Icon'
            icon={<CalendarIcon className='size-8' />}
            heading='Kalendarz'
            text='Sprawdź dni wolne w danym roku szkolnym'
          />
          <ToolElement
            href='#function'
            src='/functions/question-img.jpg'
            alt='Question mark Icon'
            icon={<HandRaisedIcon className='size-8' />}
            heading='Wkrótce...'
            text='Wkrótce dodamy nowe funkcjonalności'
          />
          <ToolElement
            href='/app/kalkulator'
            src='/functions/calculator-img.jpg'
            alt='Calculator Icon'
            icon={<CalculatorIcon className='size-8' />}
            heading='Kalkulator'
            text='Za pomocą naszego kalkulatora obliczysz swoje punkty do szkoły
                średniej'
          />
          <ToolElement
            href='#function'
            src='/functions/question-img.jpg'
            alt='Question mark Icon'
            icon={<HandRaisedIcon className='size-8' />}
            heading='Wkrótce...'
            text='Wkrótce dodamy nowe funkcjonalności'
          />
        </ContentElement>

        <ContentElement
          className='p-20 m-0 mb-20 bg-sectionbg'
          heading={"Chcemy pomóc użytkownikowi takiemu jak ty!"}
          subheading={"Nasze cele"}
          childrenClassName='container grid grid-cols-2 justify-center gap-32 items-center'
          isWhite={true}>
          <IdeaElement
            src={"/home/ideals/choice.jpg"}
            alt={"Wybór tabletek biała czy żółta?"}
            icon={<BoltIcon className='size-10 text-icons' />}
            heading={"Szybki i dobry wybór"}
            text={
              "Narzędzie do wybierania szkoły znajdzie w twoim Powiecie szkołe która według twoich wymagań, zainteresowań oraz umiejętnośći. Pasuje idealnie pod ciebie!"
            }
          />
          <IdeaElement
            src={"/home/ideals/learning.jpg"}
            alt={"Uczące się dziecko"}
            icon={<BookOpenIcon className='size-10 text-icons' />}
            heading={"Prosta Nauka"}
            text={
              "Dzięki naszym notatkom szybko i rzetelnie przygotujesz się do następnego sprawdzianu lub kartkówki."
            }
            reverse
          />

          <IdeaElement
            src={"/home/ideals/light.jpg"}
            alt={"Przyszłość"}
            icon={<StarIcon className='size-10 text-icons' />}
            heading={"Świetlana Przyszłość"}
            text={
              "Czy to diament? Nie! To twoja przyszłość oszlifowana jak brylant przy naszej pomocy."
            }
          />
        </ContentElement>

        <ContentElement
          heading='Masz jakieś pytania?'
          subheading='Sekcja FAQ'
          className='p-20 m-0 mb-40'
          childrenClassName='grid md:grid-cols-1 lg:grid-cols-2 items-start gap-14'>
          <div className='relative z-10 h-[50rem] w-full rounded-xl p-5 flex flex-col items-center shadow-custom'>
            <ul className='h-[50rem] flex flex-col gap-7 transition-all duration-short'>
              {faqData.map((item, index) => (
                <QuestionElement
                  index={index}
                  question={item.question}
                  answer={item.answer}
                  visibleAnswer={visibleAnswers}
                  handleVisible={handleVisible}
                  key={index}
                />
              ))}
            </ul>
          </div>

          <div className='w-full h-[50rem] p-5 relative z-10 shadow-2xl rounded-xl flex flex-col items-center justify-center bg-gradient-to-br from-main to-btn'>
            <h2 className='text-4xl font-bold text-white16 mb-4'>
              Masz jeszcze jakieś pytania?
            </h2>
            <p className='text-3xl text-white48 mb-6'>
              Wypełnij formularz kontaktowy, a udzielimy ci odpowiedzi.
            </p>
            <a
              href='/contact'
              className='text-3xl font-semibold text-white5 bg-btn no-underline inline-block px-3 py-4 rounded-3xl transition-all hover:scale-110 '>
              Formularz
            </a>
          </div>
        </ContentElement>
      </main>
      <Footer />
    </>
  )
}

export default Home
